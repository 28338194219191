<template>
  <div>
    <div class="nosebox">
      <section>
        <a class="logo" @click="goHome">
          <img :src="logo" alt="logo" />
        </a>
        <!-- <span class="loginBtn">
          <span @click="goLogin(1)">登录</span>
          <span> / </span>
          <span @click="goLogin(2)">注册</span>
        </span> -->
      </section>
    </div>
    <a name="top"></a>
    <section class="note">
      <div class="noticeImg"></div>
      <div id="base" class="">
        <!-- Unnamed (矩形) -->

        <!-- Unnamed (矩形) -->
        <div id="u2">
          <div id="u2_div" class=""></div>
          <!-- Unnamed () -->
          <div id="u3" style="visibility: visible;">
            <p class="text-center"><span>建搜搜服务协议及隐私政策</span></p>
          </div>
        </div>

        <!-- Unnamed (矩形) -->
        <div id="u7" class="ax_default label">
          <div id="u7_div" class=""></div>
          <!-- Unnamed () -->
          <div id="u8" class="text" style="visibility: visible;">
            <p><span>尊敬的用户：</span></p>
            <p><span>您好！</span></p>
            <p>
              <span
                >为了保障您的合法权益，更好地向您提供建搜搜平台服务，根据我国法律法规及监管部门的要求，我们对《建搜搜用户协议及隐私政策》的部分条款进行了修订，请您仔细阅读上海奕翔商务咨询有限公司在此特别提醒用户认真阅读、充分理解最新的《建搜搜用户协议及隐私政策》的全部条款，特别是其中所涉及的免除、减轻上海奕翔商务咨询有限公司责任的条款、对用户权利限制条款、争议解决和法律适用等。其中，限制、免责条款可能以黑体加粗或加下划线的形式提示您重点注意。</span
              >
            </p>
            <p>
              <span
                >您未阅读或拒绝接受本协议的所有条款的，应立即停止使用建搜搜，否则您继续使用建搜搜即视为您已同意本协议。</span
              >
            </p>
            <p><span>建搜搜用户协议及隐私政策</span></p>
            <p>
              <span><br /></span>
            </p>
            <p><span>(2020年1月6日更新)</span></p>
            <p>
              <span><br /></span>
            </p>
            <p><span>尊敬的用户：</span></p>
            <p>
              <span
                >在您成为建搜搜的用户，使用建搜搜提供的服务之前，请您认真阅读本《用户协议及隐私政策》，更好的了解我们所提供的服务以及您享有的权利义务。您开始使用建搜搜提供的服务，即表示您已经确认并接受了本文件中的全部条款。</span
              >
            </p>
            <p><span>第一部分 用户协议</span></p>
            <p>
              <span><br /></span>
            </p>
            <p>
              <span
                >本用户协议（简称“协议”）系由您与上海奕翔商务咨询有限公司（以下简称“上海奕翔商务咨询有限公司”或“我们”）就上海奕翔商务咨询有限公司提供的建搜搜软件（以下简称“本平台”或“建搜搜”）所订立的相关权利义务规范。建搜搜软件是一个严肃纯净的招聘服务软件，请您在注册、使用建搜搜之前，应认真阅读以下条款：</span
              >
            </p>
            <p><span>一、注册条款的接受</span></p>
            <p>
              <span
                >一旦您在注册页面点击或勾选“阅读并同意接受用户协议及隐私政策”相关内容后，即表示您已经阅读并且同意与上海奕翔商务咨询有限公司达成协议，成为建搜搜的用户，并接受本用户协议中的全部注册条款以及第二部分建搜搜《个人信息保护政策》和建搜搜软件内公布的其他规定（如《职位信息发布规则》、《增值服务协议》）的所有条款的约束。</span
              >
            </p>
            <p><span>二、用户注册条件</span></p>
            <p>
              <span
                >1.申请注册成为建搜搜的用户应同时满足下列全部条件：在注册之日以及此后使用建搜搜服务期间必须以招聘和/或应聘为目的；在注册之日必须年满18周岁以上。</span
              >
            </p>
            <p>
              <span
                >2.为更好的享有建搜搜的网络平台服务，用户应遵守建搜搜软件注册机制的要求，向建搜搜提供本人真实、正确、最新及完整的资料；如注册为招聘主体的用户，应保证及时更新本人提供的“单位名称、职务或岗位信息、企业邮箱”等相关授权信息及材料，并确保前述授权的真实性；用户应保证其职务行为，包括但不限于发布招聘信息、与求职者沟通等均在使用本平台期间持续有效；通过认证的用户应保持其招聘账号与对应的授权单位具有唯一性。</span
              >
            </p>
            <p>
              <span
                >3.若用户提供任何错误、不实或不完整的资料，或建搜搜有理由怀疑资料为错误、不实或不完整及违反用户注册条款的，或建搜搜有理由怀疑其用户资料、言行等有悖于“严肃纯净的招聘APP”主题或违反《建搜搜职位发布规则》的，建搜搜有权修改用户的注册昵称、个人说明、发布的信息等，或暂停或终止该用户的账号，或暂停或终止提供建搜搜提供的全部或部分服务。</span
              >
            </p>
            <p>
              <span
                >4.若用户故意提供虚假的身份信息、公司信息进行注册，发布虚假招聘信息或者求职信息的，视为严重违反本用户协议，建搜搜有权暂停或终止该用户账号并停止提供服务。虚假注册、发布虚假信息给建搜搜造成经济、名誉损失的，建搜搜将追究该用户的法律责任。</span
              >
            </p>
            <p><span>三、用户账号、密码及安全</span></p>
            <p>
              <span
                >1.在完成用户注册程序之后，用户将收到一个密码及账号。用户有义务保证密码及账号的安全。用户应对利用该密码及账号所进行的一切活动负全部责任，包括任何经由建搜搜上载、张贴、发送电子邮件或任何其它方式传送的资讯、资料、文字、软件、音乐、音讯、照片、图形、视讯、信息或其它资料，无论系公开还是私下传送，均由内容提供者承担责任。</span
              >
            </p>
            <p>
              <span
                >2.用户的密码或账号遭到未获授权的使用，或者发生其它任何安全问题时，用户应立即通知建搜搜。由于用户使用不当或者其他非因建搜搜导致的账号、密码泄漏，进而导致其资料、信息泄漏的，由用户承担其不利后果。</span
              >
            </p>
            <p>
              <span
                >3.用户不得将账号主动告知第三方或提供给第三方进行使用，例如提供给第三方进行代为购买建搜搜服务等。如因此造成用户隐私泄露或建搜搜平台损失的，用户应当承担相应的责任。</span
              >
            </p>
            <p><span>四、服务说明</span></p>
            <p>
              <span
                >1.
                建搜搜通过国际互联网为用户提供网络服务，包括在线及离线的相关业务。为使用网络服务，用户应自行配备进入国际互联网所必需的设备，包括计算机、数据机或其它存取装置，并自行支付登陆国际互联网所需要的费用。</span
              >
            </p>
            <p>
              <span
                >2.
                建搜搜在提供网络服务时，可能会对部分网络服务收取一定的费用，在此情况下，会在相关页面上做明确的提示。如用户拒绝支付该等费用，则不能使用相关的网络服务。付费业务将在本注册条款的基础上另行规定服务条款，以规范付费业务的内容和双方的权利义务，用户应认真阅读，如用户购买付费业务，则视为接受付费业务的服务条款。</span
              >
            </p>
            <p>
              <span
                >3.
                无论是付费服务还是建搜搜的免费服务均有有效期，有效期结束后服务将自动终止，且有效期不可中断或延期。除非本注册条款或其他相关服务条款另有规定，所有付费业务均不退费。</span
              >
            </p>
            <p>
              <span
                >4.
                对于利用建搜搜进行非法活动，或其言行（无论线上或者线下的）背离建搜搜严肃招聘目的的，建搜搜将严肃处理，包括将其列入黑名单、将其被投诉的情形公之于众、删除用户账号等处罚措施，给建搜搜造成经济或者名誉损失的，建搜搜将追究其法律责任。</span
              >
            </p>
            <p>
              <span
                >5.
                建搜搜有权向其用户发送广告信，或为活动等目的，向其用户发送电子邮件、短信或电话通知。除系统通知或重要信息外，用户可以通过建搜搜提供的方式选择不接收上述信息。</span
              >
            </p>
            <p>
              <span
                >6.
                为提高建搜搜用户求职招聘的成功率和效率，上海奕翔商务咨询有限公司可能会将建搜搜用户的信息公开展示范围，扩大至我们运营的其他平台上（如建搜搜等），因此，您在使用建搜搜期间，还有可能收到来自上海奕翔商务咨询有限公司运营的其他平台（建搜搜）注册用户向您发起开聊、交换微信、联系电话等相关消息或其他类似行为。您可通过本协议的第二部分《个人信息保护政策》了解我们在此期间如何保障您的个人信息的安全。</span
              >
            </p>
            <p>
              <span
                >7.建搜搜的道具等服务只在产品平台上或由建搜搜直接对外出售或者赠送使用权，用户不得在任何其他平台或渠道购买建搜搜的虚拟产品或服务。对于用户使用非官方提供或者售卖的金币、道具等虚拟产品或服务造成经济等损失的情况，建搜搜不对其负责；如因通过其他平台或他人代充等方式给建搜搜造成损失的，用户应当承担违约责任并赔偿损失。</span
              >
            </p>
            <p><span>五、有限责任条款</span></p>
            <p>
              <span
                >1.上海奕翔商务咨询有限公司将尽力为用户提供提供安全、及时、准确、高质量的服务，但不保证一定能满足用户的要求和期望，也不保证服务不会中断，对服务的及时性、安全性、准确性都不作保证。除非另有约定，否则用户因无法使用建搜搜服务，或使用网站服务未达到心理预期的，建搜搜不承担责任。</span
              >
            </p>
            <p>
              <span
                >2.对于用户通过上海奕翔商务咨询有限公司提供的服务传送的内容，建搜搜会尽合理努力按照国家有关规定严格审查，但无法完全控制经由软件/网站服务传送的内容，不保证内容的正确性、完整性或品质。因此用户在使用建搜搜服务时，可能会接触到令人不快、不适当或令人厌恶的内容。在任何情况下，建搜搜均不为用户经由软件/网站服务以张贴、发送电子邮件或其它方式传送的任何内容负责。但建搜搜有权依法停止传输任何前述内容并采取相应行动，包括但不限于暂停用户使用软件/网站服务的全部或部分，保存有关记录，并根据国家法律法规、相关政策在必要时向有关机关报告并配合有关机关的行动。</span
              >
            </p>
            <p>
              <span
                >3.对于建搜搜提供的各种第三方广告信息、链接、资讯等，建搜搜不保证其内容的正确性、合法性或可靠性，相关责任由广告主承担；并且，对于用户经由建搜搜服务与广告主进行联系或商业往来，完全属于用户和广告主之间的行为，与建搜搜无关。对于前述商业往来所产生的任何损害或损失，建搜搜不承担任何责任。</span
              >
            </p>
            <p>
              <span
                >4.对于用户上传的照片、资料、证件等，建搜搜已采用相关措施并已尽合理努力进行审核，但不保证其内容的正确性、合法性或可靠性，相关责任由上传上述内容的用户承担。</span
              >
            </p>
            <p>
              <span
                >5.用户应对其他建搜搜用户发布的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。上海奕翔商务咨询有限公司无法且不会对因前述风险而导致的任何损失或损害承担责任。</span
              >
            </p>
            <p>
              <span
                >6.是否使用本软件/网站服务下载或取得任何资料应由用户自行考虑并自负风险，因任何资料的下载而导致的用户电脑系统的任何损坏或数据丢失等后果，建搜搜不承担任何责任。</span
              >
            </p>
            <p>
              <span
                >7.对于本软件/网站策划、发起、组织或是承办的任何用户活动（包括但不限于收取费用以及完全公益的活动），建搜搜不对上述活动的效果向用户作出任何保证或承诺，也不担保活动期间用户自身行为的合法性、合理性。由此产生的任何对于用户个人或者他人的人身或者是名誉以及其他损害，应由行为实施主体承担责任。</span
              >
            </p>
            <p>
              <span
                >8.对于用户的投诉，建搜搜将尽合理努力进行核实和处理，但不保证一定能满足投诉者的要求。建搜搜有权决定是否向公众或向被投诉者公开投诉内容。对于投诉内容侵犯用户隐私权、名誉权等合法权利的，所有法律责任由投诉者承担，与建搜搜无关。</span
              >
            </p>
            <p><span>六、用户权利</span></p>
            <p><span>用户对于自己的个人信息享有以下权利：</span></p>
            <p>
              <span
                >1.随时查询及请求阅览，但因极少数特殊情况（如被网站加入黑名单等）无法查询及提供阅览的除外；</span
              >
            </p>
            <p>
              <span
                >2.随时请求补充或更正，但因极少数特殊情况（如网站或有关机关为司法程序保全证据等）无法补充或更正的除外；</span
              >
            </p>
            <p>
              <span
                >3.
                随时请求注销建搜搜账号。关于注销账号的相关规则和流程，请参见《用户注销协议》。您注销成功后，我们将根据法律法规的要求尽快删除您的个人信息或作匿名化处理。</span
              >
            </p>
            <p><span>七、用户应承诺其平台使用行为遵守以下规定：</span></p>
            <p>
              <span
                >1.
                本协议所称“平台使用”是指用户使用建搜搜平台服务所进行的任何行为，包括但不限于注册、登录、认证、查看开聊、账号管理、发布招聘信息、邀约面试以及其他通过建搜搜或建搜搜账号在建搜搜平台所进行的一切行为。</span
              >
            </p>
            <p>
              <span
                >2．上海奕翔商务咨询有限公司提醒用户在使用建搜搜服务时，应遵守《中华人民共和国合同法》、《中华人民共和国著作权法》、《全国人民代表大会常务委员会关于维护互联网安全的决定》、《中华人民共和国保守国家秘密法》、《中华人民共和国电信条例》、《中华人民共和国计算机信息系统安全保护条例》、《中华人民共和国计算机信息网络国际联网管理暂行规定》及其实施办法、《计算机信息系统国际联网保密管理规定》、《互联网信息服务管理办法》、《计算机信息网络国际联网安全保护管理办法》、《互联网电子公告服务管理规定》、《网络安全法》、《中华人民共和国劳动法》及《中华人民共和国劳动合同法》等相关中国法律法规的规定。</span
              >
            </p>
            <p>
              <span
                >3.在任何情况下，如果上海奕翔商务咨询有限公司有理由认为用户使用建搜搜服务过程中的任何行为，包括但不限于用户的任何言论和其它行为违反或可能违反上述法律和法规的任何规定，上海奕翔商务咨询有限公司可在任何时候不经任何事先通知终止向该用户提供服务。</span
              >
            </p>
            <p>
              <span
                >4.用户承诺在使用建搜搜软件期间，遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等【七条底线】。</span
              >
            </p>
            <p>
              <span
                >5.您理解并同意，建搜搜平台仅为用户提供招聘信息分享、传播及获取招聘、求职机会的平台，您必须为自己的注册、认证账号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。关于用户通过建搜搜平台上传、发布信息的具体规则，应阅读并遵守《建搜搜信息发布规则》。</span
              >
            </p>
            <p><span>八、禁止用户利用建搜搜从事下列行为:</span></p>
            <p>
              <span
                >1.通过建搜搜的服务制作、复制、发布、传播违反国家相关法律法规及「七条底线」、「九不准」规定的违法和不良信息，主要表现为：</span
              >
            </p>
            <p><span>●反对宪法所确定的基本原则的；</span></p>
            <p>
              <span
                >●危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</span
              >
            </p>
            <p>
              <span
                >●损害国家荣誉和利益的；煽动民族仇恨、民族歧视、破坏民族团结的；</span
              >
            </p>
            <p><span>●破坏国家宗教政策，宣扬邪教和封建迷信的；</span></p>
            <p><span>●散布谣言，扰乱社会秩序，破坏社会稳定的；</span></p>
            <p>
              <span
                >●散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</span
              >
            </p>
            <p><span>●侮辱或者诽谤他人，侵害他人合法权利的；</span></p>
            <p>
              <span
                >●含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它有悖道德、令人反感的内容；</span
              >
            </p>
            <p>
              <span
                >●含有中国法律、法规、规章、条例以及任何具有法律效力的规范所限制或禁止的其它内容的。</span
              >
            </p>
            <p>
              <span
                >2.使用建搜搜服务的过程中，以任何方式危害未成年人的利益。</span
              >
            </p>
            <p>
              <span
                >3.冒充任何人或机构，包含但不限于冒充建搜搜工作人员或以虚伪不实的方式陈述或谎称与任何人或机构有关的。</span
              >
            </p>
            <p>
              <span
                >4.将侵犯任何人的肖像权、名誉权、隐私权、专权、商标权、著作权、商业秘密或其它专属权利的内容上载、张贴、发送电子邮件或以其它方式传送的。</span
              >
            </p>
            <p>
              <span
                >5.将病毒或其它计算机代码、档案和程序，加以上载、张贴、发送电子邮件或以其它方式传送的。</span
              >
            </p>
            <p><span>6.跟踪或以其它方式骚扰其他用户的。</span></p>
            <p>
              <span
                >7.未经合法授权而截获、篡改、收集、储存或删除他人个人信息、电子邮件或其它数据资料，或将获知的此类资料用于任何非法或不正当目的。</span
              >
            </p>
            <p>
              <span
                >8.以任何方式干扰或企图干扰建搜搜的任何产品、任何部分或功能的正常运行，或者制作、发布、传播上述工具、方法等；</span
              >
            </p>
            <p>
              <span
                >9.未能按照建搜搜平台的流程、规则进行注册、认证或使用本服务的，违反本服务功能限制或运营策略，或采取任何措施规避前述流程、规则、限制或策略的；</span
              >
            </p>
            <p>
              <span
                >10.
                未经上海奕翔商务咨询有限公司的许可使用插件、外挂或通过其他第三方工具、运营平台或任何服务接入本服务和相关系统的；</span
              >
            </p>
            <p>
              <span
                >11．利用建搜搜账号或建搜搜平台服务从事，包括但不限于欺诈、传销、刷流量、好评、违法物品营销等任何违法兼职或犯罪活动的；
              </span>
            </p>
            <p>
              <span
                >12.
                仿冒、混淆他人账号昵称、头像、功能介绍或发布招聘内容等，或冒充、利用他人名义对外招聘的；</span
              >
            </p>
            <p>
              <span
                >13.
                未经上海奕翔商务咨询有限公司的许可，以任何目的自行或授权、允许、协助任何第三人对平台内的任何信息内容进行非法获取，用于商业用途或其他任何目的。“非法获取”是指采用包括但不限于“蜘蛛”(spider)程序、爬虫程序、拟人程序等非真实用户或避开、破坏技术措施等非正常浏览的手段、方式，读取、复制、转存、获得数据和信息内容的行为；</span
              >
            </p>
            <p>
              <span
                >14．为任何注册用户或非注册用户提供自动登录到建搜搜平台、代办或协助他人代办身份认证服务的或代售身份认证所需的相关材料或凭据等；</span
              >
            </p>
            <p>
              <span
                >15.
                其他任何导致或可能导致上海奕翔商务咨询有限公司与第三方产生纠纷、争议或诉讼的行为。</span
              >
            </p>
            <p><span>九、特别规定</span></p>
            <p>
              <span
                >1.用户如违反本协议第八条，建搜搜有权在任何时候不经任何事先通知暂停或终止向该用户提供服务。</span
              >
            </p>
            <p>
              <span
                >2.用户有下列行为或存在下述情形的，建搜搜在发现或接到投诉后，有权采取冻结用户账户或以其他方式暂停向该用户提供服务，并要求用户承担相应的损害赔偿责任：</span
              >
            </p>
            <p><span>1)发布了涉及广告、传销、直销等内容</span></p>
            <p><span>2)发布了涉及色情、淫秽内容</span></p>
            <p><span>3)发布了涉及违法/政治敏感内容</span></p>
            <p>
              <span
                >4)发布虚假信息，包括但不限于不真实的公司信息、薪资、店长身份、业务员身份、个人简历、职位信息等</span
              >
            </p>
            <p><span>5)利用建搜搜提供的服务索取他人隐私</span></p>
            <p><span>6)发布涉及人身攻击或其他侵害他人权益的内容</span></p>
            <p><span>7)发布招聘未成年人工作信息</span></p>
            <p><span>8)招聘他人从事违法活动</span></p>
            <p><span>9)以培训费、服装费等名义骗取求职者财物</span></p>
            <p><span>10)骚扰其他用户</span></p>
            <p>
              <span
                >11)发布不符合建搜搜相关服务性质的信息，如鸡汤、段子、水贴等</span
              >
            </p>
            <p>
              <span
                >12)利用本平台可能存在的漏洞恶意充值金币、获取道具等虚拟产品或服务</span
              >
            </p>
            <p>
              <span
                >13)在本平台以外的任何第三方平台（包括但不限于淘宝、闲鱼等）售卖金币、道具等虚拟产品或服务的行为</span
              >
            </p>
            <p>
              <span
                >14)通过第三方平台或渠道（如淘宝店铺等）购买金币、道具等虚拟产品或服务</span
              >
            </p>
            <p>
              <span
                >15)
                其他违反法律法规或国家政策以及损害建搜搜及其合法用户之合法权益的行为。</span
              >
            </p>
            <p>
              <span
                >3.根据我国现行的法律法规等相关规定，如用户实施前述第（4）项“发布虚假信息”的，包括但不限于用户发布的职位信息与其实际招聘的职位不符的，如用户实际招聘的职位为“保险销售、信用卡销售、理财产品销售、地产中介或销售或劳务派遣”，但与其发布的职位信息在“内容”、“类型”或其他方面并非一致或对应的，建搜搜均有权永久性拒绝对该用户提供服务或采取其他处理措施，包括但不限于“永久性冻结账号”、“永久性将其设备号、手机号等相关信息冻结”或“永久性加入‘黑名单’”等。</span
              >
            </p>
            <p><span>十、隐私政策</span></p>
            <p>
              <span
                >建搜搜依法保护用户个人信息和隐私信息。有关隐私政策的内容，详见本协议第二部分建搜搜《个人信息保护政策》。</span
              >
            </p>
            <p><span>十一、关于用户在建搜搜的上传或张贴的内容</span></p>
            <p>
              <span
                >1.用户在建搜搜上传或张贴的内容（包括但不限于照片、文字、面试经历及心得评价等），视为用户授予上海奕翔商务咨询有限公司及其关联公司免费、非独家的使用权，建搜搜有权为展示、传播及推广前述张贴内容的目的，对上述内容进行复制、修改、出版等。该使用权持续至用户书面通知建搜搜不得继续使用，且建搜搜实际收到该等书面通知时止</span
              >
            </p>
            <p>
              <span
                >2.因用户上传或张贴的内容侵犯他人权利，而导致任何第三方向上海奕翔商务咨询有限公司提出侵权或索赔要求的，用户应承担全部责任。</span
              >
            </p>
            <p>
              <span
                >3.任何第三方对于用户在建搜搜的公开使用区域张贴的内容进行复制、修改、编辑、传播等行为的，该行为产生的法律后果和责任均由行为人承担，与建搜搜无关。</span
              >
            </p>
            <p><span>十二、关于面试聊天等即时通讯服务</span></p>
            <p>
              <span
                >1.用户在接受建搜搜提供与建搜搜注册用户或建搜搜关联方用户进行提在线开聊、邀约面试等即时通讯服务时，应当遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚，并保证所传输的信息真实性等七条底线。</span
              >
            </p>
            <p>
              <span
                >2.用户通过建搜搜提供的通讯服务与其他人进行通讯所产生的文字、语音等信息，如面试聊天记录等，建搜搜可能根据法律规定及用户功能的需要进行临时存储并可能根据实际情况随时进行删除。建搜搜将对相关信息进行加密处理，除法律强制要求外，不向任何第三人泄露。</span
              >
            </p>
            <p>
              <span
                >3.建搜搜不临时存储的信息将仅用于处理投诉、诈骗检测、用户离线暂存用途。</span
              >
            </p>
            <p>
              <span
                >4.为保护其他用户隐私，用户不得将本条规定的其他用户通讯信息，如面试聊天记录等，对外进行公布。如因此造成建搜搜损失，或者侵害其他用户权利的，应当承担违约责任或赔偿责任。</span
              >
            </p>
            <p><span>十三、信息储存和限制</span></p>
            <p>
              <span
                >建搜搜有权制定一般措施及限制，包含但不限于软件服务将保留的电子邮件、聊天讯息、所张贴内容或其他上载内容的最长期间、每个账号可收发沟通讯息的最大数量及可收发的单个消息的大小。通过服务存储或传送之任何信息、通讯资料和其他内容，如被删除或未予储存，建搜搜不承担任何责任。用户同意，长时间未使用的账号，建搜搜有权关闭。同时建搜搜有权自行决定并无论通知用户与否，随时变更这些一般措施及限制。</span
              >
            </p>
            <p><span>十四、结束服务</span></p>
            <p>
              <span
                >用户若反对任何注册条款的内容或对之后注册条款修改有异议，或对建搜搜服务不满，用户有以下权利：不再使用建搜搜服务；结束用户使用建搜搜服务的资格；通知建搜搜停止该用户的服务。结束用户服务的同时，用户使用建搜搜服务的权利立即终止，建搜搜不再对用户承担任何义务。</span
              >
            </p>
            <p><span>十五、禁止商业行为</span></p>
            <p>
              <span
                >1.用户同意不对建搜搜提供的服务或服务的任何部分，进行复制、拷贝、出售、转售或用于任何其他商业目的。</span
              >
            </p>
            <p>
              <span
                >2.禁止通过职位向应聘者收费，如有不实，我们将结束用户使用建搜搜服务的资格。</span
              >
            </p>
            <p><span>十六、违约责任</span></p>
            <p>
              <span
                >1.用户使用虚假身份信息、公司信息进行注册，发布虚假招聘、求职信息，发布含有传销、色情、反动等严重违法内容，对外传播面试聊天等通讯记录等行为，视为严重违反本协议，应当承担给上海奕翔商务咨询有限公司造成的经济损失和名誉损失。</span
              >
            </p>
            <p>
              <span
                >2.因用户通过建搜搜提供的服务提供、张贴或传送内容、违反本服务条款、或侵害他人任何权利而导致任何第三人对建搜搜提出任何索赔或请求，用户应当赔偿建搜搜或其他合作伙伴的损失，包括但不限于赔偿金额、律师费和合理的调查费用等。</span
              >
            </p>
            <p>
              <span
                >3.用户在投诉其他用户有违法行为或违反本注册条款情形时，投诉者应承担不实投诉所产生的全部法律责任。如侵犯他人的合法权益，投诉人应独立承担全部法律责任。如给建搜搜造成损失的，投诉人应对建搜搜承担相应的赔偿责任。</span
              >
            </p>
            <p><span>十七、本协议条款的变更和修改</span></p>
            <p>
              <span
                >建搜搜有权依法随时对本协议的任何条款进行变更和修改。一旦发生条款变动，我们将在建搜搜软件内进行更新及提示，或将最新版本的用户协议及隐私政策以系统消息、弹窗或邮件的形式发送给用户阅读及确认接收。用户如果不同意条款的修改，应主动停止使用建搜搜或申请注销建搜搜账号，如未使用的付费权益将在注销后清空。否则，如果用户继续使用用户账号，则视为用户已经接受本协议全部条款的修改。</span
              >
            </p>
            <p><span>十八、不可抗力</span></p>
            <p>
              <span
                >1.“不可抗力”是指建搜搜不能合理控制、不可预见或即使预见亦无法避免的事件，该事件妨碍、影响或延误建搜搜根据本注册条款履行其全部或部分义务。该事件包括但不限于政府行为、自然灾害、战争、黑客袭击、电脑病毒、网络故障等。不可抗力可能导致建搜搜无法访问、访问速度缓慢、存储数据丢失、用户个人信息泄漏等不利后果。
                2.遭受不可抗力事件时，建搜搜可中止履行本用户协议项下的义务直至不可抗力的影响消除为止，并且不因此承担违约责任；但应尽最大努力克服该事件，减轻其负面影响。</span
              >
            </p>
            <p><span>十九、通知</span></p>
            <p>
              <span
                >建搜搜向其用户发出的通知，将采用系统消息、弹窗、电子邮件或页面公告的形式。本《用户协议及隐私政策》的条款修改或其他事项变更时，建搜搜可以以上述形式进行通知。</span
              >
            </p>
            <p>
              <span><br /></span>
            </p>
            <p><span>第二部分 建搜搜《个人信息保护政策》</span></p>
            <p>
              <span><br /></span>
            </p>
            <p><span>更新日期：2020年1 月 6 日</span></p>
            <p>
              <span
                >欢迎您使用我们的产品和服务！我们非常重视您的隐私保护和个人信息保护。本《个人信息保护政策》将帮助您了解以下内容：</span
              >
            </p>
            <p><span>一、引言</span></p>
            <p><span>二、我们如何收集和使用您的个人信息</span></p>
            <p><span>三、我们如何使用 Cookie 和同类技术</span></p>
            <p><span>四、我们如何共享、转让、公开披露您的个人信息</span></p>
            <p><span>五、我们如何保存及保护您的个人信息</span></p>
            <p><span>六、您的权利</span></p>
            <p><span>七、我们如何处理未成年人的个人信息</span></p>
            <p><span>八、您的个人信息如何跨境转移</span></p>
            <p><span>九、个人信息保护政策的修订</span></p>
            <p><span>十、如何联系我们</span></p>
            <p><span>十一、争议解决方式</span></p>
            <p>
              <span><br /></span>
            </p>
            <p><span>一、引言</span></p>
            <p>
              <span
                >为切实保护建搜搜用户隐私权，优化用户体验，建搜搜根据现行法规及政策，制定本《个人信息保护政策》。本《个人信息保护政策》将详细说明建搜搜在获取、管理及保护用户个人信息方面的政策及措施。本《个人信息保护政策》适用于上海奕翔商务咨询有限公司向您提供的所有服务，无论您是通过计算机设备、移动终端或其他设备获得的建搜搜服务。</span
              >
            </p>
            <p>
              <span
                >本《个人信息保护政策》旨在帮助您了解我们会收集哪些数据、为什么收集这些数据，会利用这些数据做些什么及如何保护这些数据。我们希望您在使用建搜搜服务前仔细阅读并明确您已经充分理解、接受本《个人信息保护政策》的内容，希望您可以根据自己的理解做出合适的选择。您一旦开始使用建搜搜平台服务，即表示您认同我们在本《个人信息保护政策》中所述内容。在我们更新本《个人信息保护政策》后，您继续使用我们的产品与/或服务，即意味着您同意本《个人信息保护政策》(含更新版本)内容，并且同意我们按照本《个人信息保护政策》收集、使用、保存和共享您的相关信息。此外，在将您的个人信息用于本《个人信息保护政策》未涵盖的用途时，我们会事先征求您的同意。</span
              >
            </p>
            <p><span>二、我们如何收集和使用您的个人信息</span></p>
            <p>
              <span
                >我们深知个人信息对您的重要性，也深知为您的信息提供有效保护是我们业务健康可持续发展的基石。感谢您对建搜搜平台的使用和信任！我们致力于维持您对我们的信任，恪守适用法律和我们对您的承诺，尽全力保证您的个人信息安全和合理使用。我们郑重承诺，我们已按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。</span
              >
            </p>
            <p>
              <span
                >我们仅会出于本《个人信息保护政策》所述的以下目的，收集和使用您的个人信息。</span
              >
            </p>
            <p><span>1、注册账号</span></p>
            <p>
              <span
                >您首先需要注册一个建搜搜账号成为建搜搜的注册用户。当您注册时，您至少需要向我们提供您本人的手机号码，我们将通过发送短信验证码的方式来验证您的身份是否有效。如果您不提供这些信息，不影响您通过web或wap页面进行浏览、搜索。</span
              >
            </p>
            <p><span>2、通过认证成为招聘用户</span></p>
            <p>
              <span
                >在注册成功后，为了保证发布职位的真实可信，您在建搜搜上发布职位前，需要通过实名认证，包括提供真实姓名、性别、身份证号，以及面部特征信息。发布职位前，您还需要提供所在单位名称、担任岗位，根据认证需要可能需要提供单位邮箱、工牌、在职证明其中的至少一种信息以证明您可以代表该单位进行招聘。如果您提交的单位首次在建搜搜上发布招聘信息，那么您还需要提供营业执照以验证单位的合法性。</span
              >
            </p>
            <p><span>3、发布职位</span></p>
            <p>
              <span
                >您可以通过【弹窗】选择是否允许我们的产品收集您的定位信息（包括GPS定位、IP地址），如果您要使用本平台的职位信息发布功能，则需同意提供您的定位信息。该信息将仅被用于为您提供岗位地址选择功能、进行账号安全检测以及向您推荐您可能感兴趣的求职用户或职位。建搜搜不会将上述信息用作实现上述功能之外的其他用途。拒绝提供位置信息仅会使您无法享受基于位置的安全检测和个性化推荐，而不会影响您正常使用本平台的其他功能。</span
              >
            </p>
            <p><span>4、创建在线简历 </span></p>
            <p>
              <span
                >在注册成功后，如果您需找工作，那么您首先需要填写一份在线简历，包括生日、年龄、开始工作时间、过往工作经历（公司名称、担任职务、在职时间段、工作内容描述）、过往教育经历（毕业学校、学历、在校时间段、在校经历描述）、联系方式（微信、手机、电子邮箱）、期望工作（期望城市、期望薪资、期望行业、期望岗位）。建搜搜将根据您的以上信息，根据推荐算法为您推荐合适的工作岗位。</span
              >
            </p>
            <p>
              <span
                >基于公平性原则，如果您在在线简历隐藏状态下主动查看职位。那么您所查看职位所属的招聘用户可以看到您的在线简历信息。</span
              >
            </p>
            <p><span>5、浏览招聘信息和求职用户的在线简历</span></p>
            <p>
              <span
                >为了让您快速地找到您所需要职位或求职用户，我们可能会收集您使用我们的产品与/或服务的设备信息（包括设备名称、设备型号、设备识别码、操作系统和应用程序版本、分辨率、服务提供商网络ID（PLMN））、浏览器类型来为您提供信息展示的最优方式。我们也会为了不断改进和优化上述的功能来使用您的上述信息。</span
              >
            </p>
            <p>
              <span
                >您也可以通过搜索功能来筛选您所需要的工作信息或求职用户。我们会保留您的搜索内容以方便您重复输入或为您展示与您搜索内容相关岗位或求职用户。请您注意，您的搜索关键词信息无法单独识别您的身份，不属于您的个人信息，我们有权以任何的目的对其进行使用；只有当您的搜索关键词信息与您的其他信息相互结合使用并可以识别您的身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本《个人信息保护政策》对其进行处理与保护。</span
              >
            </p>
            <p><span>6、购买增值服务</span></p>
            <p>
              <span
                >在您选择购买我们的金币、虚拟道具、付费权益等增值服务时，您可以选择第三方支付机构所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的购买明细、订单号与交易金额信息与这些支付机构共享以实现其确认您的支付指令并完成支付。</span
              >
            </p>
            <p>
              <span
                >“关联方”指存在关联关系的主体，包括但不限于存在母子公司、总分公司关系，直接或间接的控股关系，实际控制人、董事、监事、高级管理人员与该主体存在直接或者间接控制的关系，协议控制VIE结构，或从证券市场披露、其官方宣传等能够确认存在关联的关系等；</span
              >
            </p>
            <p>
              <span
                >“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。</span
              >
            </p>
            <p><span>7、在线沟通</span></p>
            <p>
              <span
                >当您在使用建搜搜平台与建搜搜或建搜搜的求职用户或招聘用户进行线上沟通时，根据双方沟通意向，可能需要交换联系方式（包括电话、微信），要求发送附件简历文件。在一方主动发起且另一方主动同意的情况下，需相互交换联系方式，或求职用户将附件简历文件发送给招聘用户。</span
              >
            </p>
            <p>
              <span
                >当求职用户与招聘用户在线上达成面试意向，并通过建搜搜提供的约面试功能成功发出面试邀请，求职用户接受后，需要向对方展示手机号，用于线下前往面试地点时候的必要联系。</span
              >
            </p>
            <p><span>8、改进我们产品所必须的功能</span></p>
            <p>
              <span
                >我们可能会收集您的IP地址、某按钮的点击使用时间和频率、某页面的使用时间和频率、某些关键配置的选项值、崩溃日志、错误日志等，用来将您感兴趣的职位、求职用户或求职招聘过程中您可能需要的服务信息展示给您，同时有可能用于统计我们产品的用户数量、分析产品的使用状况、网页升级改版、浏览器兼容性检测、排查崩溃原因、减少崩溃情况，以不断改进我们的产品。这些信息不涉及到您的个人身份等隐私信息。</span
              >
            </p>
            <p><span>9、保障招聘求职安全所必须的功能</span></p>
            <p>
              <span
                >为了增强建搜搜平台的求职招聘、在线面试等过程中的安全性，尽可能保障您或其他用户在招聘求职过程中的人身、财产安全免遭侵害，更好地预防求职诈骗等安全风险，更准确地识别违反法律法规或建搜搜相关协议规则的情况。我们可能使用或整合您的账户信息、设备信息、软件使用信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，用于判断您的账号风险、进行店铺主体认证、检测我们认为有风险的行为以及防范平台安全事件，并采取必要的记录、审计、分析、处置措施。</span
              >
            </p>
            <p><span>10、家庭住址（非必须） </span></p>
            <p>
              <span
                >对于部分求职用户用户，用户可以提供家庭住址，用于在浏览职位时候查看工作地点距离居住地址的距离，和查看家庭住址附近的职位。</span
              >
            </p>
            <p><span>11.客服功能</span></p>
            <p>
              <span
                >我们可能会利用手机的信息（包括您的手机号、电子邮件地址）与您直接沟通或互动。发现账号异常后可能会向您发送通知。为保证您的账号安全，客服在接受您的咨询反馈前，会使用您的账号信息核验您的身份。</span
              >
            </p>
            <p><span>12.我们通过间接获得方式收集到您的个人信息</span></p>
            <p>
              <span
                >当您通过我们产品或服务使用建搜搜关联方、合作方服务时，您同意我们根据实际业务及合作需要从我们关联方、合作方处接收、使用、汇总、分析经您授权同意其向我们提供的您的个人信息。</span
              >
            </p>
            <p><span>13.其他用途</span></p>
            <p>
              <span
                >在符合法律法规的前提下，我们可能会将您的个人信息在匿名化、汇总、脱敏和加密处理后，形成统计信息或用户画像（但画像无法识别任何用户个人），为您展示和推送相关服务、产品或功能。当我们要将信息用于本《个人信息保护政策》未载明的其他用途，将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</span
              >
            </p>
            <p><span>14.征得授权同意的例外</span></p>
            <p>
              <span
                >根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下收集、使用一些必要的个人信息：</span
              >
            </p>
            <p><span>1. 与国家安全、国防安全直接相关的；</span></p>
            <p>
              <span>2. 与公共安全、公共卫生、重大公共利益直接相关的；</span>
            </p>
            <p>
              <span>3. 与犯罪侦查、起诉、审判和判决执行等直接相关的；</span>
            </p>
            <p>
              <span
                >4.
                出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span
              >
            </p>
            <p><span>5. 所收集的个人信息是您自行向社会公众公开的；</span></p>
            <p>
              <span
                >6.
                从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道；</span
              >
            </p>
            <p><span>7. 根据您的要求签订和履行合同所必需的；</span></p>
            <p>
              <span
                >8.
                学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</span
              >
            </p>
            <p>
              <span
                >9.
                用于维护建搜搜平台产品和/或服务的安全稳定运行所必需的，包括发现、处置产品或服务的故障；</span
              >
            </p>
            <p><span>10. 法律法规规定的其他情形。</span></p>
            <p>
              <span
                >如果您对我们收集和使用您的个人信息有任何疑问或需要提供进一步的信息，请通过本《个人信息保护政策》公布的联系方式与我们联系。</span
              >
            </p>
            <p><span>三、我们如何使用Cookie和同类技术</span></p>
            <p>
              <span
                >为确保网站正常运转，我们会在您的计算机或移动设备上存储名为
                Cookie 的小数据文件。Cookie
                通常包含用户身份标识符、城市名称称以及一些字符。Cookie主要的功能是便于您使用网站产品和服务，以及帮助网站统计独立访客数量等。运用Cookie技术，我们能够为您提供更加周到的服务。</span
              >
            </p>
            <p>
              <span
                >我们不会将 Cookie
                用于本《个人信息保护政策》所述目的之外的任何用途。您可根据自己的偏好管理或删除
                Cookie。有关详情，请参见
                【AboutCookies.org】。您可以清除计算机上保存的所有
                Cookie，大部分网络浏览器都设有阻止 Cookie
                的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置，但您可能因为该等修改，无法登录或使用依赖于Cookie的百度提供的服务或功能。</span
              >
            </p>
            <p>
              <span
                >您可以通过更改您的浏览器设置限制建搜搜对Cookie的使用。以【Chrome】浏览器为例，您可以在【Chrome】浏览器右上方的下拉菜单的“浏览器设置”中，通过“【设置-高级-清楚浏览数据】”，选择清除您的Cookie。</span
              >
            </p>
            <p><span>四、我们如何共享、转让、公开披露您的个人信息</span></p>
            <p>
              <span
                >我们不会向第三方共享、转让您的个人信息，除非经过您本人事先授权同意，或者共享、转让的个人信息是去标识化处理后的信息，且共享第三方无法重新识别此类信息的自然人主体。</span
              >
            </p>
            <p><span>1. 共享</span></p>
            <p>
              <span
                >我们会以高度的勤勉义务对待您的信息。除以下情形外，未经您同意，我们不会与除上海奕翔商务咨询有限公司及其关联公司外的任何第三方公司、组织和个人分享您的信息：</span
              >
            </p>
            <p><span>1.1为实现相关功能或服务与建搜搜关联方共享</span></p>
            <p>
              <span
                >为保障您在本平台公开展示的信息向建搜搜关联方（如“建搜搜”）的注册用户展示时的一致性，并方便统一管理您的信息展示，我们有可能会将您去标识化后的个人信息与非个人信息与“建搜搜”平台进行共享。</span
              >
            </p>
            <p><span>1.2为实现特定功能而与业务合作伙伴共享</span></p>
            <p>
              <span
                >当【软件服务提供商、智能设备提供商或系统服务提供商】与我们联合为您提供服务时，当您需要使用地理位置功能时，为实现这一功能，我们可能会收集您的位置信息及相关设备信息（包括硬件型号、操作系统版本号、国际移动设备身份识别码（IMEI）、网络设备硬件地址（MAC））经过去标识化后并提供给前述提供商。</span
              >
            </p>
            <p><span>目前，我们的授权合作伙伴包括以下类型：</span></p>
            <p>
              <span
                >●服务平台或服务提供商。建搜搜各产品接入了丰富的第三方服务。当您选择使用该第三方服务时，您授权我们将该信息提供给第三方服务平台或服务提供商，以便其基于相关信息为您提供服务，包括认证服务提供商，当您绑定建搜搜与认证平台账号时，我们将弹出授权确认页面，在向您告知收集上述信息并得到您授权的情况下，我们会将您的简历信息提供给认证平台，用于评价您的信用分。</span
              >
            </p>
            <p>
              <span
                >●软硬件/系统服务提供商。当第三方软硬件/系统产品或服务与建搜搜的产品或服务结合为您提供服务时，经您授权，我们会向第三方软硬件/系统服务提供商提供您必要的个人信息，以便您使用服务，或用于我们分析产品和服务使用情况，来提升您的使用体验。</span
              >
            </p>
            <p>
              <span
                >●广告、咨询类服务商/广告主。未经您授权，我们不会将您的个人信息与提供广告、咨询类服务商共享。但我们可能会将经处理无法识别您的身份且接收方无法复原的信息，包括经匿名化处理的用户画像，与广告或咨询类服务商或广告主共享，以帮助其在不识别您个人的前提下，提升广告有效触达率，以及分析我们的产品和服务使用情况等。</span
              >
            </p>
            <p>
              <span
                >1.3
                为向您展示或推荐更多匹配的求职用户或满足期望的招聘职位。</span
              >
            </p>
            <p>
              <span
                >您理解并同意我们有可能将您在建搜搜展示的全部或部分信息，展示在建搜搜平台供其用户进行查看、开聊及其他类似行为，因此您可能接收到来自建搜搜的用户向您发出的开聊等信息。</span
              >
            </p>
            <p>
              <span
                >我们承诺在共享过程中，尽最大可能保障您的数据和隐私不受侵害，并以不低于本《个人信息保护政策》所要求的保密和安全措施来处理该些信息。</span
              >
            </p>
            <p>
              <span
                >如果您拒绝本条款的内容，请通过本《个人信息保护政策》公布的联系方式与我们联系。</span
              >
            </p>
            <p>
              <span
                >1.4
                对我们与之共享个人信息的其他公司、组织和个人，我们会与其签署严格的保密协议，并要求第三方以不低于本《个人信息保护政策》所要求的保密和安全措施来处理该些信息。</span
              >
            </p>
            <p><span>2. 转让</span></p>
            <p>
              <span
                >我们不会将您的个人信息转让给除上海奕翔商务咨询有限公司及其关联公司外的任何公司、组织和个人，但以下情形除外：</span
              >
            </p>
            <p><span>2.1. 事先获得您的明确授权或同意；</span></p>
            <p>
              <span
                >2.2.
                满足法律法规、法律程序的要求或强制性的政府要求或司法裁定；</span
              >
            </p>
            <p>
              <span
                >2.3.
                如果我们或我们的关联公司涉及合并、分立、清算、资产或业务的收购或出售等交易，您的个人信息有可能作为此类交易的一部分而被转移，我们将确保该等信息在转移时的机密性，并要求新的持有您个人信息的公司、组织继续受此个人信息保护政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</span
              >
            </p>
            <p><span>3. 公开披露</span></p>
            <p><span>我们仅会在以下情形下，公开披露您的个人信息：</span></p>
            <p><span>3.1 获得您的明确同意；</span></p>
            <p>
              <span
                >3.2
                基于法律法规、法律程序、诉讼或政府主管部门强制性要求下。</span
              >
            </p>
            <p>
              <span
                >4. 共享、转让、公开披露个人信息时事先征得授权同意的例外</span
              >
            </p>
            <p>
              <span
                >在以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</span
              >
            </p>
            <p><span>4.1. 与国家安全、国防安全直接相关的；</span></p>
            <p>
              <span>4.2. 与公共安全、公共卫生、重大公共利益直接相关的；</span>
            </p>
            <p>
              <span>4.3. 与犯罪侦查、起诉、审判和判决执行等直接相关的；</span>
            </p>
            <p>
              <span
                >4.4.
                出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span
              >
            </p>
            <p><span>4.5. 您自行向社会公众公开的个人信息；</span></p>
            <p>
              <span
                >4.6.
                从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</span
              >
            </p>
            <p>
              <span>4.7. 根据个人信息主体要求签订和履行合同所必需的；</span>
            </p>
            <p>
              <span
                >4.8.
                用于维护所提供的产品或服务的安全稳定运行所必需的，包括发现、处置产品或服务的故障；</span
              >
            </p>
            <p><span>4.9. 法律法规规定的其他情形。</span></p>
            <p>
              <span
                >根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</span
              >
            </p>
            <p><span>五、我们如何保存及保护您的个人信息</span></p>
            <p><span>1. 保存期限</span></p>
            <p>
              <span
                >您在使用建搜搜产品及服务期间，我们将持续为您保存您的个人信息。如果您注销帐户或主动删除上述信息，我们将依据网络安全法等法律法规规定保存您的信息。在您注销帐户或主动删除上述信息后，我们不会再对您的个人信息进行商业化使用，但我们可能会对您的个人信息进行删除或匿名化处理后使用。</span
              >
            </p>
            <p><span>2. 保存地域</span></p>
            <p>
              <span
                >您的个人信息均储存于中华人民共和国境内。如部分产品或服务涉及跨境，我们需要向境外传输您的个人信息，我们会严格按照法律法规的规定执行，并保证您的个人信息安全。</span
              >
            </p>
            <p>
              <span
                >3.
                一般而言，我们仅为实现目的所必需的最短时间保留您的个人信息，下列情况下，我们有能因需符合法律要求，更改个人信息的存储时间：</span
              >
            </p>
            <p><span>●为遵守适用的法律法规等有关规定；</span></p>
            <p><span>●遵守法院判决、裁定或其他法律程序的规定；</span></p>
            <p><span>●为遵守相关政府机关或法定授权组织的要求；</span></p>
            <p><span>●我们有理由确信需要遵守法律法规等有关规定；</span></p>
            <p>
              <span
                >●为执行相关服务协议或本《个人信息保护政策》、维护社会公共利益，为保护们的客户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益所合理必需的用途。</span
              >
            </p>
            <p>
              <span
                >当我们的产品或服务发生停止运营的情形时，我们将采取例如，推送通知、公告等形式通知您，并在合理的期限内删除或匿名化处理您的个人信息。</span
              >
            </p>
            <p><span>4. 保护措施 </span></p>
            <p>
              <span
                >4.1我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据时受SSL协议加密保护；我们同时对建搜搜网站提供HTTPS协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全培训课程，加强员工对于保护个人信息重要性的认识。</span
              >
            </p>
            <p>
              <span
                >4.2我们从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。目前，我们的重要信息系统已经通过网络安全等级保护的测评。</span
              >
            </p>
            <p>
              <span
                >4.3我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本《个人信息保护政策》所述目的所需的期限内保留您的个人信息（除非法律有强制的存留要求）。</span
              >
            </p>
            <p>
              <span
                >4.4互联网并非绝对安全的环境，使用建搜搜平台服务时，我们强烈建议您不要使用非建搜搜平台推荐的通信方式发送您的信息。您可以通过我们的服务建立联系和相互分享。当您通过我们的服务创建交流、分享时，您可以自主选择沟通、分享的对象，作为能够看到您的联络方式、交流信息或分享内容等相关信息的第三方。</span
              >
            </p>
            <p>
              <span
                >4.5在使用建搜搜服务进行沟通时，请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即通过第十章公布的联系方式联络我们，以便我们根据您的申请采取相应措施。</span
              >
            </p>
            <p>
              <span
                >4.6请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在评论、沟通时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。</span
              >
            </p>
            <p><span>5. 安全事件通知</span></p>
            <p>
              <span
                >5.1.
                我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。</span
              >
            </p>
            <p>
              <span
                >5.2.
                个人信息泄露、毁损、丢失属于公司级特大安全事件，我们会负责定期组织工作组成员进行安全预案演练，防止此类安全事件发生。若一旦不幸发生，我们将按照最高优先级启动应急预案，由安全部、政府关系部、法务部等多个部门组成应急响应小组，在最短时间内追溯原因并减少损失。</span
              >
            </p>
            <p>
              <span
                >5.3.
                在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时通过软件内部提醒、邮件、短信或电话等方式向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况通过上述方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span
              >
            </p>
            <p>
              <span
                >我们将不定期更新并公开安全风险、个人信息安全影响评估报告等有关内容，您可通过建搜搜公告方式获得。</span
              >
            </p>
            <p><span>六、您的权利</span></p>
            <p>
              <span
                >按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</span
              >
            </p>
            <p><span>1. 访问和修改您的个人信息</span></p>
            <p><span>1）访问修改姓名 </span></p>
            <p>
              <span
                >尚未完成实名认证的，可以在【我的&gt;在线简历&gt;点击姓名】，进行修改；</span
              >
            </p>
            <p>
              <span
                >已经完成实名认证的求职用户，可以在 我的&gt;在线简历
                中显示和修改</span
              >
            </p>
            <p>
              <span
                >已经完成实名认证的招聘用户，可以在 我的&gt;招聘管理
                中显示和修改；
              </span>
            </p>
            <p><span>2）访问和修改性别 </span></p>
            <p>
              <span
                >尚未完成实名认证的求职用户，可以在
                我的&gt;在线简历&gt;点击姓名&gt;性别，更改显示；
              </span>
            </p>
            <p><span>已经完成实名认证的用户，不能修改性别； </span></p>
            <p><span>3）访问和修改简历内容 </span></p>
            <p><span>求职用户可以在 我的&gt;在线简历 中修改简历内容 </span></p>
            <p><span>4）访问和修改手机号 </span></p>
            <p>
              <span>用户可以在 我的&gt;设置&gt;修改手机号 中更换手机号 </span>
            </p>
            <p><span>5）访问和修改微信号 </span></p>
            <p>
              <span>招聘用户可以在 我的&gt;招聘设置&gt;微信号 中修改 </span>
            </p>
            <p>
              <span
                >求职用户可以在 我的&gt;在线简历&gt;点击姓名&gt;微信号中添加修改
              </span>
            </p>
            <p><span>6）访问和修改住址 </span></p>
            <p>
              <span
                >求职用户可以在推荐列表&gt;排序方式&gt;附近 &gt; 设置/更改住址
                来添加或修改住址，如果您无法通过上述链接访问该等个人信息，您可以通过本《个人信息保护政策》公布的联系方式与我们联系。</span
              >
            </p>
            <p>
              <span
                >您在使用我们的产品和服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，请通过本《个人信息保护政策》的“十、如何联系我们”中公布的联系方式与我们联系。</span
              >
            </p>
            <p>
              <span
                >您发现我们处理的关于您的个人信息有错误时，您有权对错误或不完整的信息作出更正或更新，您可以通过上述段落中列明的方式提出更正或补充申请。为保障安全，我们将在您行使更正权前对您的身份进行验证。</span
              >
            </p>
            <p><span>2. 删除您的个人信息</span></p>
            <p>
              <span
                >在以下情形中，您可以向我们提出删除个人信息的请求，您可以通过“1.
                访问和修改您的个人信息”中列明的方式或本《个人信息保护政策》公布的联系方式随时与我们联系：</span
              >
            </p>
            <p>
              <span
                >2.1.如果我们违反法律法规或与您的约定收集、使用、与他人共享或转让您的个人信息；</span
              >
            </p>
            <p>
              <span
                >2.2
                如果我们违反法律法规规定或与您的约定，公开披露您的个人信息，您有权要求我们立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息。</span
              >
            </p>
            <p>
              <span>2.3我们没有征求您的明确同意，收集了您的个人信息。 </span>
            </p>
            <p>
              <span>2.4我们违反了与您的约定来使用和处理您的个人信息。</span>
            </p>
            <p>
              <span
                >您注销了建搜搜账号、卸载或者不再使用我们的产品（或服务）。我们停止对您提供服务。</span
              >
            </p>
            <p><span>3.改变授权范围</span></p>
            <p><span>3.1改变或撤回敏感信息权限</span></p>
            <p>
              <span
                >您可以在设备本身操作系统中关闭地理位置、通讯录、摄像头、麦克风等权限改变同意范围或撤回您的授权。</span
              >
            </p>
            <p><span>3.2拒接受推送及营销信息</span></p>
            <p>
              <span
                >您可以通过手机设置关闭“推送通知”拒绝消息的推送，通过回复“TD”等退订我们的营销短信。</span
              >
            </p>
            <p>
              <span
                >3.3请您理解，特定的业务功能和服务将需要您的信息才能完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。</span
              >
            </p>
            <p><span>4. 注销您的账户</span></p>
            <p>
              <span
                >您可以通过本《个人信息保护政策》公布的联系方式，申请注销建搜搜账号。需要注意的是，我们为了保护您或他人的合法权益、履行互联网平台的法律责任，在您的账号处于特殊状态下，虽然我们会支持您的请求，但您在成功注销后可能无法再次注册或使用建搜搜平台和服务，具体事项以《注销协议》为准。</span
              >
            </p>
            <p><span>5. 提前获知产品和服务停止运营</span></p>
            <p>
              <span
                >建搜搜愿一直陪伴您，若因特殊原因导致建搜搜平台被迫停止运营，我们将按照法律法规的要求，在产品和/或服务的主页面或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您，并将停止对您个人信息的收集，同时会按照法律规定对所持有的您的个人信息进行删除或匿名化处理等。</span
              >
            </p>
            <p><span>6. 响应您的上述请求</span></p>
            <p>
              <span
                >6.1为保障安全，您需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</span
              >
            </p>
            <p>
              <span
                >6.2对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</span
              >
            </p>
            <p><span>7. 响应请求的例外</span></p>
            <p>
              <span
                >在以下情形中，按照法律法规要求，我们将无法响应您的上述请求：</span
              >
            </p>
            <p><span>●与国家安全、国防安全直接相关的；</span></p>
            <p><span>●与公共安全、公共卫生、重大公共利益直接相关的；</span></p>
            <p><span>●与犯罪侦查、起诉、审判和执行判决等直接相关的；</span></p>
            <p>
              <span
                >●我们有充分证据表明您存在主观恶意或滥用权利的（如您的请求将危害公共安全和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围）；</span
              >
            </p>
            <p>
              <span
                >●响应个人信息主体的请求将导致您或其他个人、组织的合法权益受到严重损害的；</span
              >
            </p>
            <p><span>涉及商业秘密的。</span></p>
            <p><span>七、我们如何处理未成年人的个人信息</span></p>
            <p><span>建搜搜非常重视对未成年人信息的保护。</span></p>
            <p>
              <span
                >基于我们的产品、网站和服务的性质，我们不向16周岁以下的未成年人提供服务，因此无收集相关信息。若您是已满16周岁不满18周岁的未成年人，建议您请您的监护人仔细阅读本《个人信息保护政策》，并在征得您的监护人同意的前提下使用我们的产品和/或服务或向我们提供信息。</span
              >
            </p>
            <p>
              <span
                >如您的监护人不同意您按照本《个人信息保护政策》使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。</span
              >
            </p>
            <p>
              <span
                >如果监护人发现我们在未获监护人同意的情况下收集了未成年人的个人信息，请通过本《个人信息保护政策》公布的联系方式联系我们，我们会尽快删除相关数据。</span
              >
            </p>
            <p><span>八、您的个人信息如何进行跨境转移</span></p>
            <p>
              <span
                >原则上，我们在中国境内收集和产生的个人信息仅存储在中国境内。如部分产品或服务涉及跨境，我们需要向境外传输您的个人信息，我们会严格按照法律法规的规定执行，并保证您的个人信息安全。</span
              >
            </p>
            <p><span>九、本《个人信息保护政策》如何更新</span></p>
            <p>
              <span
                >我们可能适时修订本《个人信息保护政策》内容。如该等变更会导致您在本《个人信息保护政策》项下权利的实质变化，我们将在变更生效前，通过【推送系统消息、电子邮件、短信或网站公告】等方式通知您。</span
              >
            </p>
            <p>
              <span
                >若您不同意该等变更应停止使用建搜搜平台产品和服务，或通过本《个人信息保护政策》公布的联系方式要求暂时封停您的账号。若您继续使用我们的产品和/或服务，即表示您同意受修订后的本《个人信息保护政策》的约束。</span
              >
            </p>
            <p>
              <span>本《个人信息保护政策》所指的重大变更包括但不限于：</span>
            </p>
            <p>
              <span
                >1.
                我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</span
              >
            </p>
            <p>
              <span>2. 个人信息共享、转让或公开披露的主要对象发生变化；</span>
            </p>
            <p>
              <span
                >3. 您参与个人信息处理方面的权利及其行使方式发生重大变化；</span
              >
            </p>
            <p>
              <span>4. 其他可能对您的个人信息权益产生重大影响的变化时；</span>
            </p>
            <p><span>5. 个人信息出境情况发生变更时。</span></p>
            <p><span>十、如何联系我们</span></p>
            <p>
              <span
                >如果您对本《个人信息保护政策》及我们对您的个人信息的处理有任何疑问、意见、建议或投诉，请通过400-600-5600联系我们。</span
              >
            </p>
            <p>
              <span
                >在一般情况下，我们会在15日内对您的请求予以答复。我们还设立了个人信息保护专职部门，您可以通过400-600-5600与我们联系。</span
              >
            </p>
            <p><span>十一、争议解决</span></p>
            <p>
              <span
                >本《个人信息保护政策》与《建搜搜用户协议及隐私政策》共同构成您使用建搜搜服务的基本协议文件。本《个人信息保护政策》适用中华人民共和国现行法律法规。与本《个人信息保护政策》相关的争议，如果您对我们的回复或解决方案不满意，需要通过法律途径解决的，应将该争议提交至深圳仲裁委员会，其仲裁裁决是终局的。
              </span>
            </p>
            <p>
              <span><br /></span>
            </p>
            <p>
              <span><br /></span>
            </p>
            <p><span>附录：定义</span></p>
            <p>
              <span
                >本《个人信息保护政策》中使用的特定词语，具有如下含义：</span
              >
            </p>
            <p>
              <span
                >1.
                “我们”或“上海奕翔商务咨询有限公司”，指上海奕翔商务咨询有限公司</span
              >
            </p>
            <p>
              <span
                >2. “关联公司”，指上海奕翔商务咨询有限公司的其他关联公司。</span
              >
            </p>
            <p>
              <span
                >3.
                “您”或“用户”，指使用上海奕翔商务咨询有限公司运营的平台产品或服务的注册用户以及收费服务的购买方。</span
              >
            </p>
            <p>
              <span
                >4.
                “建搜搜平台”或“建搜搜软件”，指建搜搜PC端及建搜搜手机客户端、建搜搜微信小程序。</span
              >
            </p>
            <p>
              <span
                >5.
                “建搜搜关联方”，指上海奕翔商务咨询有限公司及其关联公司单独或共同运营的平台
                ，包括但不限于建搜搜等。</span
              >
            </p>
            <p>
              <span
                >6.
                “个人信息”，指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人信息包括个人基本信息、个人身份信息、个人生物识别信息、网络身份标识信息、个人健康生理信息、个人教育工作信息、个人财产信息、个人通信信息、联系人信息、个人上网记录、个人常用设备信息、个人位置信息等。为免疑义，个人信息包括但不限于个人敏感信息。</span
              >
            </p>
            <p><span>7. “个人信息主体”，指个人信息所标识的自然人。</span></p>
            <p>
              <span
                >8.
                “个人敏感信息”，指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。个人敏感信息包括个人财产信息、个人健康生理信息、个人生物识别信息、个人身份信息、网络身份标识信息等。</span
              >
            </p>
            <p>
              <span
                >9.
                “去标识化”，指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别个人信息主体的过程。</span
              >
            </p>
            <p>
              <span
                >10.
                “匿名化”，指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。</span
              >
            </p>
            <p>
              <span
                >11.
                “中国”或“中国境内”，是指本协议仅适用于中国大陆，不适用于中国其他地区如香港和澳门以及台湾。</span
              >
            </p>
            <p>
              <span><br /></span>
            </p>
          </div>
        </div>
      </div>
    </section>
    <section></section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logo: require("@/assets/img/logo.png"),
    };
  },
  methods: {
    goLogin() {
      this.$router.push({ path: "/Login" });
    },
    goHome() {
      this.$router.push({ path: "/Home/index" });
    },
  },
  mounted() {},
};
</script>
<style scoped>
.nosebox {
  background-color: #131d28;
}
section .logo {
  margin-top: 5px;
}
.nosebox section {
  height: 80px;
  display: flex;
  align-items: center;
}
.loginBtn {
  float: right;
  padding: 12px 20px;
  background: #2573f1;
  color: #fff;
  border-radius: 50px;
  margin-left: auto;
}
.loginBtn span {
  cursor: pointer;
}
.note {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
}
.noticeImg {
  height: 90px;
  margin: 30px 0 50px;
  background-image: url(../../assets/icon/notice.png);
}
</style>
